<template>
  <page-container title="日志管理" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="操作账号" prop="useraccount">
              <a-input v-model.trim="queryParams.useraccount" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="日志类型" prop="logtype">
              <a-select v-model="queryParams.logtype">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in logTypes" :key="index" :value="index">{{item}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="时间范围" prop="logdate">
              <a-range-picker v-model="queryParams.logdate">
              </a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="日志内容" prop="logcontent">
              <a-input v-model.trim="queryParams.logcontent" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">日志列表</h3>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="logid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="logtype" slot-scope="value">
            {{logTypes[value]}}
          </span>
          <a-tooltip slot="logtime" slot-scope="value">
            <template slot="title">{{moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}}</template>
            <span>{{moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss')}}</span>
          </a-tooltip>
          <span slot="operation" slot-scope="value, record">
            <a-button type="link" size="small" @click="deleteConfirm(value, record)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>

<script>
import { getLogListByCondition, deleteLog } from 'A/xtpz.js'
import {getCache, getItemFromArrayByKey} from 'U'
import store from '@/store'
import moment from 'moment'
import callCenter from "C/modals/callCenter";

export default {
  name: 'logManagement',
  data() {
    return {
      counts:'',
      showAdvanced: false,
      moment,
      logTypes: ['增加', '删除', '修改', '用户登录'],
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '日志管理',
          path: ''
        },
      ],
      queryParams: {
        useraccount: '', //操作账号
        logtype: '',
        logdate: [],
        // stattime: '',
        // endtime: '',
        logcontent: '',
      },
      tableColumns: [
        {
          title: '操作账号',
          dataIndex: 'useraccount',
          key: 'useraccount',
          ellipsis: true,
        },
        {
          title: '日志类型',
          dataIndex: 'logtype',
          key: 'logtype',
          ellipsis: true,
          scopedSlots: { customRender: 'logtype' },
        },
        {
          title: '时间',
          dataIndex: 'logtime',
          key: 'logtime',
          ellipsis: true,
          scopedSlots: { customRender: 'logtime' },
        },
        {
          title: '日志内容',
          dataIndex: 'logcontent',
          key: 'logcontent',
          ellipsis: true
        },
        {
          title: '操作',
          key: 'operation',
          width: 80,
          align: 'center',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
    }
  },
  computed: {
    starttime() {
      if(this.queryParams.logdate && this.queryParams.logdate[0]) {
        return moment(this.queryParams.logdate[0]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
    endtime() {
      if(this.queryParams.logdate && this.queryParams.logdate[1]) {
        return moment(this.queryParams.logdate[1]).format('YYYYMMDD');
      }else {
        return ''
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableData();
    },
    resetQueryParams() {
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
        this.useraccount = logininfo.useraccount;
        this.usertype = logininfo.usertype;
      }
      console.log(logininfo)
      this.tableLoading = true;
      let params = {
        useraccount: this.usertype==0?this.queryParams.useraccount:this.useraccount,
        logtype: this.queryParams.logtype,
        starttime: this.starttime,
        endtime: this.endtime,
        logcontent: this.queryParams.logcontent,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      }
      getLogListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.logid);
        },
        onCancel() {},
      });
    },
    delete(logid) {
      if(logid) {
        let params = {
          logid
        };
        deleteLog(params).then(res => {
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
  }
}
</script>
